<template>
    <!-- eslint-disable-next-line vue/no-v-html - santized in computed processed -->
    <div data-marked v-html="processed" />
</template>

<script setup>
import DOMPurify from "dompurify";
import { marked } from "marked";
import { computed, useAttrs } from "vue";

const renderer = new marked.Renderer();
const attrs = useAttrs();

renderer.link = function (href, title, text) {
    if (href.startsWith("mailto:")) {
        return `<a href="${href}" style="color: inherit; text-decoration: underline;">${text}</a>`;
    }
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
};

if (attrs.class) {
    // adds classes to paragraph tag if class attribute is present
    // would be good to make this generic when there is time
    renderer.paragraph = function (text) {
        return `<p class="${attrs.class}">${text}</p>`;
    };
}

marked.use({
    breaks: true,
    gfm: true,
    silent: true,
    renderer: renderer,
});

const props = defineProps({
    text: {
        type: String,
        default: () => "",
    },
});
const processed = computed(() => {
    return DOMPurify.sanitize(marked.parse(props.text));
});
</script>
